@tailwind base;
@tailwind components;
@tailwind utilities;

.PopoverContent {
  width: var(--radix-popper-anchor-width);
  max-height: var(--radix-popover-content-available-height);
}

/* html,
body {
  padding: 0;
  margin: 0;
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
body {
  background: #000;
  font-family:
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    sans-serif;
  overflow: hidden;
} */

/** Swiper styles **/

/* .swiper {
  user-select: none;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;

  height: 100%;

  padding: 0px 0px;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20%;
  position: relative;
  box-sizing: border-box;
}

.swiper-slide-content {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
} */

/* .swiper-slide-3f38 {
  background-color: rgba(51, 51, 51, 1);
} */
/* .swiper-slide-content-cd26 {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 48px 48px;
  gap: 0px;
}
.swiper-slide-text-79be {
  color: rgba(255, 255, 255, 1);
  text-align: left;
  font-size: 24px;
  line-height: 1.5;
  font-weight: bold;
} */

/* .swiper-slide-content {
  height: 100% !important;
} */

/* .swiper-slide-prev .swiper-slide-content {
  height: 40vh !important;
  max-height: 40vh !important;
} */

/* .swiper-slide-active .swiper-slide-content {
  height: 40vh !important;
  max-height: 40vh !important;
} */

.pull-top {
  margin-top: calc(-1 * (env(safe-area-inset-top) + 2rem));
}

.swiper-slide-next .swiper-slide-content {
  height: 9vh;
  min-height: 6rem;
  /* height: 23.6vh !important;
  min-height: 23.6vh !important; */
}

/* .swiper-slide .swiper-slide-content {
  height: 9vh;
  min-height: 6rem;
} */

.swiper-slide-content {
  height: 9vh;
  min-height: 6rem;
}

.swiper-slide {
  height: auto !important;
}

.swiper-button-prev,
.swiper-button-next {
  --vertical-button-width: calc(var(--swiper-navigation-size) / 44 * 27);
  transform: rotate(90deg);
  margin-top: 0;
  margin-left: calc(0px - var(--vertical-button-width) / 2);
  right: auto !important;
  left: var(--swiper-navigation-top-offset, 50%) !important;
}
.swiper-button-prev,
.swiper-rtl .swiper-button-prev {
  top: var(--swiper-navigation-sides-offset, 10px);
  bottom: auto;
}
.swiper-button-next,
.swiper-rtl .swiper-button-next {
  bottom: var(--swiper-navigation-sides-offset, 10px);
  top: auto;
}

.swiper-rtl .swiper-button-prev::after {
  content: 'prev';
}
.swiper-rtl .swiper-button-next::after {
  content: 'next';
}

html {
  /* scroll-behavior: smooth !important; */
  font-size: 16px;
}

@media screen and (min-width: 1025px) {
  html {
    font-size: 18px;
  }
}

body {
  margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top);
  margin-bottom: env(safe-area-inset-bottom);
  margin-left: env(safe-area-inset-left);
  margin-right: env(safe-area-inset-right);
}

/* Use the same attribute selector plus your header's class/id. */
body[data-scroll-locked] .fixed-header {
  /* Use the same 15px margin, or read from the custom property if it's reliable */
  padding-right: var(--removed-body-scroll-bar-size, 15px);
}

lite-youtube {
  max-width: unset !important;
}

/* start shadcn/ui */
/* start shadcn/ui template */
@layer base {
  :root {
    --background: 0 0% 100%;
    /* --background: 224 71.4% 4.1%; */
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 221.2 83.2% 53.3%;
    /* --primary-foreground: 210 40% 98%; */
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --input-foreground: 214.3 31.8% 91.4%;
    --ring: 221.2 83.2% 53.3%;
    --radius: 0rem;
  }

  .dark {
    /* --background: 222.2 84% 4.9%; */
    --background: 224 71.4% 4.1%;
    /* --background: 224 71.4% 0.1%; */
    /* --foreground: 210 40% 98%; */
    --foreground: 210 20% 98%;
    /* --card: 222.2 84% 4.9%; */
    --card: 224 71.4% 4.1%;
    /* --card: 224 71.4% 0.1%; */
    /* --card-foreground: 210 40% 98%; */
    --card-foreground: 210 20% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 217.2 91.2% 59.8%;
    /* --primary-foreground: 222.2 47.4% 11.2%; */
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    /* --muted-foreground: 212.7 26.8% 83.9%; */
    /* --muted-foreground: 220 13% 91%; */
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --input-foreground: 217.2 32.6% 40%;
    --ring: 224.3 76.3% 48%;
  }
}
/* end shadcn/ui template */

/* start shadcn/ui installed base */
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings:
      'rlig' 1,
      'calt' 1;
  }
}
/* end shadcn/ui installed base */
/* end shadcn/ui */

/* html {
  -webkit-text-size-adjust: 100%;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
  tab-size: 4;
  scroll-behavior: smooth;
}
body {
  font-family: inherit;
  line-height: inherit;
  margin: 0;
}
h1,
h2,
p,
pre {
  margin: 0;
}
*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: currentColor;
}
h1,
h2 {
  font-size: inherit;
  font-weight: inherit;
}
a {
  color: inherit;
  text-decoration: inherit;
}
pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    Liberation Mono, Courier New, monospace;
}
svg {
  display: block;
  vertical-align: middle;
  shape-rendering: auto;
  text-rendering: optimizeLegibility;
}
pre {
  background-color: rgba(55, 65, 81, 1);
  border-radius: 0.25rem;
  color: rgba(229, 231, 235, 1);
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    Liberation Mono, Courier New, monospace;
  overflow: scroll;
  padding: 0.5rem 0.75rem;
}

.shadow {
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.rounded {
  border-radius: 1.5rem;
}
.wrapper {
  width: 100%;
}
.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 768px;
  padding-bottom: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  color: rgba(55, 65, 81, 1);
  width: 100%;
}
#welcome {
  margin-top: 2.5rem;
}
#welcome h1 {
  font-size: 3rem;
  font-weight: 500;
  letter-spacing: -0.025em;
  line-height: 1;
}
#welcome span {
  display: block;
  font-size: 1.875rem;
  font-weight: 300;
  line-height: 2.25rem;
  margin-bottom: 0.5rem;
}
#hero {
  align-items: center;
  background-color: hsla(214, 62%, 21%, 1);
  border: none;
  box-sizing: border-box;
  color: rgba(55, 65, 81, 1);
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 3.5rem;
}
#hero .text-container {
  color: rgba(255, 255, 255, 1);
  padding: 3rem 2rem;
}
#hero .text-container h2 {
  font-size: 1.5rem;
  line-height: 2rem;
  position: relative;
}
#hero .text-container h2 svg {
  color: hsla(162, 47%, 50%, 1);
  height: 2rem;
  left: -0.25rem;
  position: absolute;
  top: 0;
  width: 2rem;
}
#hero .text-container h2 span {
  margin-left: 2.5rem;
}
#hero .text-container a {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 0.75rem;
  color: rgba(55, 65, 81, 1);
  display: inline-block;
  margin-top: 1.5rem;
  padding: 1rem 2rem;
  text-decoration: inherit;
}
#hero .logo-container {
  display: none;
  justify-content: center;
  padding-left: 2rem;
  padding-right: 2rem;
}
#hero .logo-container svg {
  color: rgba(255, 255, 255, 1);
  width: 66.666667%;
}
#middle-content {
  align-items: flex-start;
  display: grid;
  gap: 4rem;
  grid-template-columns: 1fr;
  margin-top: 3.5rem;
}
#learning-materials {
  padding: 2.5rem 2rem;
}
#learning-materials h2 {
  font-weight: 500;
  font-size: 1.25rem;
  letter-spacing: -0.025em;
  line-height: 1.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.list-item-link {
  align-items: center;
  border-radius: 0.75rem;
  display: flex;
  margin-top: 1rem;
  padding: 1rem;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter,
    -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  width: 100%;
}
.list-item-link svg:first-child {
  margin-right: 1rem;
  height: 1.5rem;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter,
    -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  width: 1.5rem;
}
.list-item-link > span {
  flex-grow: 1;
  font-weight: 400;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter,
    -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.list-item-link > span > span {
  color: rgba(107, 114, 128, 1);
  display: block;
  flex-grow: 1;
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 1rem;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter,
    -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.list-item-link svg:last-child {
  height: 1rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  width: 1rem;
}
.list-item-link:hover {
  color: rgba(255, 255, 255, 1);
  background-color: hsla(162, 47%, 50%, 1);
}
.list-item-link:hover > span {
}
.list-item-link:hover > span > span {
  color: rgba(243, 244, 246, 1);
}
.list-item-link:hover svg:last-child {
  transform: translateX(0.25rem);
}
#other-links {
}
.button-pill {
  padding: 1.5rem 2rem;
  transition-duration: 300ms;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter,
    -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  align-items: center;
  display: flex;
}
.button-pill svg {
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter,
    -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  flex-shrink: 0;
  width: 3rem;
}
.button-pill > span {
  letter-spacing: -0.025em;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.button-pill span span {
  display: block;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.25rem;
}
.button-pill:hover svg,
.button-pill:hover {
  color: rgba(255, 255, 255, 1) !important;
}
#nx-console:hover {
  background-color: rgba(0, 122, 204, 1);
}
#nx-console svg {
  color: rgba(0, 122, 204, 1);
}
#nx-repo:hover {
  background-color: rgba(24, 23, 23, 1);
}
#nx-repo svg {
  color: rgba(24, 23, 23, 1);
}
#nx-cloud {
  margin-bottom: 2rem;
  margin-top: 2rem;
  padding: 2.5rem 2rem;
}
#nx-cloud > div {
  align-items: center;
  display: flex;
}
#nx-cloud > div svg {
  border-radius: 0.375rem;
  flex-shrink: 0;
  width: 3rem;
}
#nx-cloud > div h2 {
  font-size: 1.125rem;
  font-weight: 400;
  letter-spacing: -0.025em;
  line-height: 1.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
#nx-cloud > div h2 span {
  display: block;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.25rem;
}
#nx-cloud p {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 1rem;
}
#nx-cloud pre {
  margin-top: 1rem;
}
#nx-cloud a {
  color: rgba(107, 114, 128, 1);
  display: block;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-top: 1.5rem;
  text-align: right;
}
#nx-cloud a:hover {
  text-decoration: underline;
}
#commands {
  padding: 2.5rem 2rem;
  margin-top: 3.5rem;
}
#commands h2 {
  font-size: 1.25rem;
  font-weight: 400;
  letter-spacing: -0.025em;
  line-height: 1.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
#commands p {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5rem;
  margin-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
details {
  align-items: center;
  display: flex;
  margin-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
}
details pre > span {
  color: rgba(181, 181, 181, 1);
  display: block;
}
summary {
  border-radius: 0.5rem;
  display: flex;
  font-weight: 400;
  padding: 0.5rem;
  cursor: pointer;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter,
    -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
summary:hover {
  background-color: rgba(243, 244, 246, 1);
}
summary svg {
  height: 1.5rem;
  margin-right: 1rem;
  width: 1.5rem;
}
#love {
  color: rgba(107, 114, 128, 1);
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-top: 3.5rem;
  opacity: 0.6;
  text-align: center;
}
#love svg {
  color: rgba(252, 165, 165, 1);
  width: 1.25rem;
  height: 1.25rem;
  display: inline;
  margin-top: -0.25rem;
}
@media screen and (min-width: 768px) {
  #hero {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  #hero .logo-container {
    display: flex;
  }
  #middle-content {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
} */

/* start ory elements dark mode overrides */
.dark {
  /* Map Ory input variables to local theme variables */
  --input-background-default: hsl(var(--input));
  --input-foreground-primary: hsl(var(--foreground));
  --input-foreground-secondary: hsl(var(--muted-foreground));
  --input-foreground-tertiary: hsl(var(--muted-foreground));
  --input-border-default: hsl(var(--border));
  --input-border-focus: hsl(var(--ring));
  --input-background-disabled: hsl(var(--muted));
  --input-foreground-disabled: hsl(var(--muted-foreground));

  /* --- Card/Interface Overrides (Attempt 3) --- */
  --interface-background-default-primary: hsl(
    var(--background)
  ); /* Primary bg (likely card) */
  --interface-foreground-default-primary: hsl(
    var(--foreground)
  ); /* Primary text */
  --interface-background-default-secondary: hsl(
    var(--muted)
  ); /* Secondary bg -> App dark muted */
  --interface-foreground-default-secondary: hsl(
    var(--muted-foreground)
  ); /* Card secondary text -> App dark muted text */
  --interface-foreground-default-tertiary: hsl(
    var(--foreground)
  ); /* Card tertiary text -> App dark foreground (for contrast) */
  --interface-border-default-primary: hsl(var(--border)); /* Primary border */

  /* Also override inverted versions to cover all bases */
  --interface-background-default-inverted: hsl(var(--background));
  --interface-foreground-default-inverted: hsl(var(--foreground));
  --interface-border-default-inverted: hsl(var(--border));

  /* Override Checkbox defaults too if needed */
  --checkbox-background-default: hsl(var(--input));
  --checkbox-border-checkbox-border-default: hsl(var(--border));
  --checkbox-foreground-default: hsl(
    var(--foreground)
  ); /* Might be white/transparent */
  --checkbox-background-checked: hsl(var(--primary));
  --checkbox-border-checkbox-border-checked: hsl(var(--primary));
  --checkbox-foreground-checked: hsl(var(--primary-foreground));

  /* Override for the specific form background variable */
  --form-background-default: hsl(var(--background));

  /* Override for links */
  --button-link-brand-brand: hsl(var(--primary));

  /* Add other Ory variable overrides here if needed for buttons, cards, etc. */
  /* --button-secondary-background-default: hsl(var(--secondary)); */
}
/* end ory elements dark mode overrides */
